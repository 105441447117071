/// <reference path="linq-to-array.ts"/>

namespace Ozytis {
    export class WebApi {
        public static processResponse(response: any, type: string = null): void {
            if (response.data && typeof(response.data) == "object") {
                let data = response.data;

                if (response.data.results && typeof (response.data.results) == "object" && typeof(response.data.__count) == "number") {
                    // OData v2 multiple-entities response
                    data = response.data.results;
                    if (type) {
                        // adjust type information to make it appear as a regular array
                        let s = type.indexOf('<');
                        let e = type.lastIndexOf('>');
                        if (s >= 0) {
                            if (e < s) throw new Error("Invalid type name: " + type);
                            type = type.substring(s + 1, e);
                            type = type + '[]';
                        }
                    }
                }

                let isArray = Object.prototype.toString.call(data) === '[object Array]';
                let valuePrototype: Object = null;
                if (isArray) {
                    if (type) {
                        if (type.substring(type.length - 2) != '[]') throw new Error("Type should be an array: " + type);
                        type = type.substring(0, type.length - 2);
                    }
                }

                if (type && type.contains('<')) {
                    console.log('Generic types not yet supported for WebApi response, type information will be ignored');
                    type = null;
                }

                if (type) {
                    let typePath = type.split('.');
                    for (let n of typePath) {
                        valuePrototype = (valuePrototype || window)[n];
                        if (!valuePrototype)
                            throw new Error("Type or namespace \"" + n + "\" does not exist for type \"" + type + "\"");
                    }
                    valuePrototype = (<any>valuePrototype).prototype;
                }

                if (isArray) {
                    for (let value of data) {
                        if (valuePrototype)
                            value.__proto__ = valuePrototype;
                        if (typeof (value.processWebApiResponse) == "function")
                            value.processWebApiResponse();
                    }
                }
                else if (typeof (data.processWebApiResponse) == "function") {
                    if (valuePrototype)
                        data.__proto__ = valuePrototype;
                    data.processWebApiResponse();
                }
            }
        }

        public static makeErrorMessage(errorResponse: any) {
            let httpResponse = /*<angular.IHttpPromiseCallbackArg<any>>*/errorResponse;

            if (typeof (httpResponse.headers) === 'function' && httpResponse.headers()['x-displayableerror']) {
                return httpResponse.headers()['x-displayableerror'];
            }

            if (httpResponse && httpResponse.status !== undefined) {
                if (httpResponse.status == 400) {
                    if (Object.prototype.toString.call(httpResponse.data) === '[object Array]') {
                        return (<any[]>httpResponse.data).select(e => e.toString()).toArray().join(", ");
                    }
                    else {
                        return httpResponse.data.toString();
                    }
                } else if (httpResponse.status == 500) {
                    return "Erreur lors de l'exécution de votre requête sur le serveur";
                } else if (httpResponse.status == 404) {
                    return "La ressource ou action demandée n'existe pas sur le serveur";
                } else if (httpResponse.status == 401) {
                    return "Vous n'êtes pas autorisé à effectuer cette action";
                } else {
                    if (httpResponse.status == -1)
                        return "Connexion avec le serveur interrompue";
                    return "Erreur " + httpResponse.status;
                }
            }
            else if (Object.prototype.toString.call(errorResponse) === '[object Array]') {
                let errors = <string[]>errorResponse;
                if (errors.all(e => typeof (e) === 'string')) {
                    return errors.join(', ');
                }
            }

            return "Erreur non spécifiée";
        }

        public static displayError(errorMessage: string): (error: any) => void {
            return error => {
                alert(errorMessage + ': ' + WebApi.makeErrorMessage(error));
            };
        }
    }
}